import React, {Component} from 'react'
import './question_template_panel.scss'
import icon_show from '../../../../images/project_entry/question_type/icon_show.png'
import SingleTemplateInfo from './Question_template_info/Single_template_info/single_template_info'
import NpsTemplateInfo from './Question_template_info/Nps_template_info/nps_template_info'
import BlankTemplateInfo from './Question_template_info/Blank_template_info/blank_template_info'
import ProportionTemplateInfo from './Question_template_info/Proportion_template_info/proportion_template_info'
import SortTemplateInfo from './Question_template_info/Sort_template_info/sort_template_info'
import ScoreTemplateInfo from './Question_template_info/Score_template_info/score_template_info'
import MatrixSingleTemplateInfo from './Question_template_info/Matrix_single_template_info/matrix_single_template_info'
import MatrixScoreTemplateInfo from './Question_template_info/Matrix_score_template_info/matrix_score_template_info'
import MatrixNpsTemplateInfo from './Question_template_info/Matrix_nps_template_info/matrix_nps_template_info'
import PictureTemplateInfo from './Question_template_info/Picture_template_info/picture_template_info'
import {getQuestionType, getQuestionTypeName} from '../Question_rules/question_type_rule'

export default class question_template_panel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMouseOn: false,//鼠标是否悬浮
      tooltipTopDistance: 0,
      tooltipArrowTopDistance: 0,// 悬浮框的箭头
    }
  }

  // 监听hover on
  onMouseOver = (index) => {
    let tooltipTopDistance = 0
    let id = 'question-template-card-item-wrapper-' + index
    // let question_item = document.getElementById(id)
    let question_item = document.getElementsByClassName(`${this.props.template_name}-question-template-card-item-wrapper-${index}`)[0]
    if (!question_item) return // 是否有模版题目
    let question_item_rect = question_item.getBoundingClientRect()
    let question_item_rect_top = question_item_rect.top
    let question_item_rect_bottom = question_item_rect.bottom
    let question_item_height = window.getComputedStyle(question_item).height
    let question_item_height_val = parseInt(question_item_height.slice(0, question_item_height.length - 2))

    let clientHeight = document.documentElement.clientHeight || document.body.clientHeight

    let question_template_container = document.getElementsByClassName('setting-right')[0]
    let question_template_container_rect = question_template_container.getBoundingClientRect()
    let question_template_container_rect_top = question_template_container_rect.top

    let operaion_row = document.getElementById('operaion-row')
    let operaion_row_height = window.getComputedStyle(operaion_row).height
    let operaion_row_height_val = parseInt(operaion_row_height.slice(0, operaion_row_height.length - 2))

    let question_item_rect_to_bottom = clientHeight - question_item_rect_bottom - operaion_row_height_val - 16 - 20 - 20
    if (question_item_rect_to_bottom + question_item_height_val < 0) {
      return // 以防万一 不见了 还能悬浮
    }
    // let tooltip_id = "question-template-tooltip-" + index
    // let tooltip_item = document.getElementById(tooltip_id)

    // let tooltip_id = "question-template-tooltip-" + index
    let tooltip_item = document.getElementsByClassName(`${this.props.template_name}-question-template-tooltip-${index}`)[0]
    // let tooltip_item_height = window.getComputedStyle(tooltip_item).height;
    if (!tooltip_item) return

    this.setState({
      isMouseOn: true,
    }, () => {
      let tooltip_item_height = window.getComputedStyle(tooltip_item).height
      let tooltip_item_height_val = parseInt(tooltip_item_height.slice(0, tooltip_item_height.length - 2))
      let tooltip_item_height_half_val = Math.ceil(tooltip_item_height_val / 2)

      if (question_item_rect_to_bottom < tooltip_item_height_half_val) { // 如果题目的距离小于悬浮框的距离
        tooltipTopDistance = clientHeight - operaion_row_height_val - 16 - 20 - 20 - tooltip_item_height_val - question_template_container_rect_top - question_item_height_val
      } else {
        tooltipTopDistance = question_item_rect_top - question_template_container_rect_top - tooltip_item_height_half_val - parseInt(question_item_height_val / 2)
      }
      let tooltipArrowTopDistance = question_item_rect_top - parseInt(question_item_height_val / 2) - question_template_container_rect_top - 5
      this.setState({
        tooltipTopDistance: tooltipTopDistance,
        tooltipArrowTopDistance: tooltipArrowTopDistance,
      })


    })


    // 注意 弹窗要有了 才能有弹窗的宽度和高度
  }

  // 监听hover out
  onMouseOut = (index) => {
    this.setState({isMouseOn: false})
  }
  // 根据题目类型选择模版
  chooseQuestionTemplate = (item, index) => {
    let type = item.question_type
    if (type === 3 || type === 18) { //简单题
      return <BlankTemplateInfo></BlankTemplateInfo>
    } else if (type === 7 || type === 11) {
      return <NpsTemplateInfo dataList={item}></NpsTemplateInfo>
    } else if (type === 6) {
      return <ProportionTemplateInfo dataList={item}></ProportionTemplateInfo>
    } else if (type === 1 || type === 2 || type === 12 || type === 13) {
      return <SingleTemplateInfo dataList={item}></SingleTemplateInfo>
    } else if (type === 4) {
      return <SortTemplateInfo dataList={item}></SortTemplateInfo>
    } else if (type === 5) {
      return <ScoreTemplateInfo dataList={item}></ScoreTemplateInfo>
    } else if (type === 8 || type === 9 || type === 16 || type === 22 || type === 23) {
      return <MatrixSingleTemplateInfo dataList={item}></MatrixSingleTemplateInfo>
    } else if (type === 14) {
      return <MatrixScoreTemplateInfo dataList={item}></MatrixScoreTemplateInfo>
    } else if (type === 15) {
      return <MatrixNpsTemplateInfo dataList={item}></MatrixNpsTemplateInfo>
    } else if (type === 10) {
      return <PictureTemplateInfo dataList={item}></PictureTemplateInfo>
    }
    return ''
  }

  addTemplateQuestion(item) {
    this.props.addTemplateQuestion(item)
  }

  render() {
    return (
      <>
        {
          this.props.question_template_list.length ?
            <div className={`question-template-card`}>
              {
                this.props.question_template_list.map((item, index) => {
                  return <div
                    className={`question-template-card-item-wrapper ${this.props.template_name}-question-template-card-item-wrapper-${index}`}
                    id={`question-template-card-item-wrapper-${index}`} key={index}
                    onClick={this.addTemplateQuestion.bind(this, item)}>
                    <div className="question-template-card-item-icon" onMouseOver={() => this.onMouseOver(index)}
                         onMouseOut={() => this.onMouseOut(index)}>
                      <img src={icon_show} alt=""/>
                      <div
                        className={`question-template-tooltip ${this.state.isMouseOn ? '' : 'isHide'} ${this.props.template_name}-question-template-tooltip-${index}`}
                        id={`question-template-tooltip-${index}`} style={{top: this.state.tooltipTopDistance + 'px'}}>
                        <div className="question-template-name-wrapper">
                          <div
                            className="question-template-name">{getQuestionTypeName(getQuestionType(item.question_type))}-
                          </div>
                          <div className="question-template-name" dangerouslySetInnerHTML={{__html: item.name}}></div>
                          <div className={`${item.image.val ? 'question-template-pic' : 'isHide'}`}>
                            <img src={item.image.val} alt=""/>
                          </div>
                        </div>
                        {/* 题目名字 */}
                        {this.chooseQuestionTemplate(item, index)}
                      </div>
                      <div className={`question-template-tooltip-arrow ${this.state.isMouseOn ? '' : 'isHide'}`}
                           style={{top: this.state.tooltipArrowTopDistance + 'px'}}
                           id={`question-template-tooltip-arrow-${index}`}></div>
                    </div>
                    {
                      item.hasOwnProperty('is_search') && item.is_search ?
                        <div className="question-template-card-item-name"
                             dangerouslySetInnerHTML={{__html: item.name_for_search}}></div> :
                        <div className="question-template-card-item-name"
                             dangerouslySetInnerHTML={{__html: item.name}}></div>
                    }

                  </div>
                })
              }
            </div>
            : ''
        }
      </>
    )
  }
}
