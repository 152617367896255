import React, {Component} from 'react'
import './CommentSingleChart.scss'
import {getPieOption} from '../../util/Chart_config/Pie_chart_config'
import {getBarOption} from '../../util/Chart_config/Bar_chart_config'
import {formatChart} from '../../util/Chart_config/Config'
import {getStackbarOption} from '../../util/Chart_config/Stackbar_chart_config_survey'
import {getMatrixLineOption} from '../../util/Chart_config/Matrix_line_config'
import {getCustomBarOption} from '../../util/Chart_config/Custom_bar_chart_config'
import MaxdiffList from './Chart_display/maxdiff_list'
import ChartList from './chart_list'
import BlankList from './blank_list'
import TableList from './table_list'
import MatrixNpsList from './Matrix_nps_chart/matrix_nps_list'
import OpenBlankList from './open_blank_list'
import MaxChartPopup from './Popup/max_chart_popup'
import MaxListPopup from './Popup/max_list_popup'
import DetailChartPopup from './Popup/detail_chart_popup'
import DetailListPopup from './Popup/detail_list_popup'
import {exportImg} from '../../util/utils'
import cur_icon_search from '../../images/emotion_analysis/cur_icon_search.png'
// import { saveAs } from 'file-saver'
import {getRelatedCommentList, geWordCloudQuestion} from '../../api/panelApi'
import {ConfigProvider, Empty, Image, message} from 'antd'
import Loading from '../../components/Loading/loading'
import XmSelect from '../../components/Select/xm_select'
import zhCN from 'antd/lib/locale/zh_CN'
import Heatmap from 'heatmap.js'

export default class CommentSingleChart extends Component {
  constructor(props) {
    super(props)
    this.maxChartRef = React.createRef()
    this.maxListRef = React.createRef()
    this.detailChartRef = React.createRef()
    this.detailListRef = React.createRef()
    this.state = {
      name: 'CommentSingleChart',
      dataList: {},
      needRefresh: false,
      chart_flag: 0,// 0 为饼图 1 为 列表
      isMaxSreen: false,// 是否大屏幕
      showMaxChartPopup: false, // 是否显示放大图表的对话框
      showDetailChartPopup: false,//是否显示柱子相亲列表的对话框
      wordcloud_image: '',
      loading_detail_chart: '',//点击柱子的接口等待
      loadingText: '数据较大，加载中',
      max_chart_desired_user_list: null,// 放大窗口的点击柱子列表
      max_chart_option_distribution: null,// 放大窗口的点击柱子图表
      isMouseOn: false,//鼠标是否悬浮
      showQuestionName: false,// 是否显示问题名称
      enlarge_question_img: false,//是否显示放大图片
      isRegion: false,//是否是区域
      precision_id: 0,//热力图精度0: 5% 1:10% 2:20%
      precision_index: 0,
      precision_id_max: 0,//热力图精度0: 5% 1:10% 2:20%
      precision_index_max: 0,
      precision_list: [
        {key: 0, value: '高精度', percent: 2},
        {key: 1, value: '中精度', percent: 5},
        {key: 2, value: '低精度', percent: 8},
      ],
      precision_list_single: [
        {key: 0, value: '高精度', percent: 2},
        {key: 1, value: '中精度', percent: 5},
        {key: 2, value: '低精度', percent: 8},
      ],
      precision_list_region: [
        {key: 0, value: '区域', percent: 10},
      ],
      question_id: '',
      card_chart_width: 0,
    }
  }

  componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      // this.setState({
      //   question_id: this.props.dataList.question_id
      // }, () => {
      //   this.toInitChart()
      // })
      this.toInitChart()
    }

    if (this.props.dataList.type === 20) {
      //防止缩小放大屏幕时候 一行显示2个/3个的时候 热例图没有重新渲染
      let that = this
      this.interval = setInterval(() => {
        let card_chart = document.querySelector('.preinstall-card')
        if (card_chart) {
          let card_chart_width_cur = card_chart.offsetWidth
          let isChange = false
          if (this.state.card_chart_width <= 558 && card_chart_width_cur > 558) {
            isChange = true
          } else if (this.state.card_chart_width > 558 && card_chart_width_cur <= 558) {
            isChange = true
          }
          if (isChange) {
            that.setState({
              card_chart_width: card_chart_width_cur,
            })
            that.generateHeatmap()
          }
        }
      }, 2000)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.interval = null
    clearInterval(this.interval)
    this.setState = () => {
    }
  }

  componentDidUpdate() {
    if (this._isMounted && this.state.needRefresh) {
      this.toInitChart()
      this.setState({
        needRefresh: false,
      })
    }
    // if (this.props.dataList.question_id !== this.state.question_id) {
    //   this.setState({
    //     question_id: this.props.dataList.question_id
    //   }, () => {
    //     this.toInitChart()
    //   })
    // }
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
      return {
        needRefresh: true,
        dataList: props.dataList,
        dataList_prev: props.dataList,
        wordcloud_image: '',
        chart_flag: 0, //防止筛选后 词云亮
      }
    }
    return null

  }

  toInitPieChart(id, isMaxSreen) {
    let data = this.props.dataList.data
    let isConsumer = this.props.isConsumer || false
    let showOptionImg = true
    let option = getPieOption(data, isConsumer, isMaxSreen, showOptionImg)
    formatChart(id, option)
  }

  toInitBarChart(id, isMaxSreen) {
    let data = this.props.dataList.data
    let xAxisList = this.props.dataList.xAxisList
    let sum = this.props.dataList.sum
    let isConsumer = this.props.isConsumer || false
    let showOptionImg = true
    let option_img = this.props.dataList.option_img
    let option = getBarOption(xAxisList, data, sum, isConsumer, isMaxSreen, showOptionImg, option_img)
    formatChart(id, option)
  }

  toInitBarChart2(id, isMaxSreen) {
    let cmp = function (a, b) {
      return b.preference - a.preference
    }
    let data_list = this.props.dataList.data_list.sort(cmp) || []
    let data = data_list.map(item2 => {
      return item2.preference
    })
    let xAxisList = data_list.map(item2 => {
      return item2.option_name
    })
    let sum = this.props.dataList.sum || 1
    let isConsumer = this.props.isConsumer || false
    let showOptionImg = true
    let option_img = this.props.dataList.option_img
    let option_obj = {
      question_type: this.props.dataList.type || '',
      occurence_number_list: data_list.sort(cmp) || [],
    }
    let option = getBarOption(xAxisList, data, sum, isConsumer, isMaxSreen, showOptionImg, option_img, option_obj)
    formatChart(id, option)
  }

  toInitMatrixLineChart(id, isMaxSreen) {
    let res = this.props.dataList.chart_list
    let showOptionImg = true
    let option = getMatrixLineOption(res, showOptionImg)
    formatChart(id, option)
  }

  toInitStackBarChart(id, isMaxSreen, type) {
    let xAxisList = this.props.dataList.xAxisList
    let seriesBarList = this.props.dataList.seriesBarList
    let series = []
    let sum_arr = []
    let isConsumer = this.props.isConsumer || false
    let option_img = []
    let option_left_img = []
    let option_up_img = []
    if (type === 4) {
      option_img = this.props.dataList.option_img
    } else if (type === 8 || type === 9) {
      option_left_img = this.props.dataList.option_left_img
      option_up_img = this.props.dataList.option_up_img
    }
    seriesBarList.forEach((item, index) => {
      let obj = {
        name: item.name,
        type: 'bar',
        barWidth: '50%', //柱子宽度
        barMaxWidth: 150,
        stack: 'overlap',
        data: item.data,
      }
      for (let i = 0; i < item.data.length; i++) {
        if (!sum_arr[i] && sum_arr[i] !== 0) {
          sum_arr[i] = 0
          sum_arr[i] = sum_arr[i] + item.data[i]
        } else {
          sum_arr[i] = sum_arr[i] + item.data[i]
        }
      }
      series.push(obj)
    })
    let showOptionImg = true
    let option_obj = {
      question_type: type,
      option_img: option_img,
      option_left_img: option_left_img,
      option_up_img: option_up_img,
    }
    let option = getStackbarOption(xAxisList, series, sum_arr, isMaxSreen, isConsumer, showOptionImg, option_obj)
    formatChart(id, option)
  }

  // 自定义bar 但是没有下面三个黄色的
  toInitCustomBarChart(id, isMaxSreen) {
    // let id = this.props.single_id
    let xAxisList = this.props.dataList.x_axis
    let data = this.props.dataList.data

    let sum = data.reduce(function (prev, curr) {
      return prev + curr
    }, 0)

    let option = getCustomBarOption(xAxisList, data, sum)
    formatChart(id, option)
  }

  toInitChart(isMaxSreen) {
    let id = 'chart_' + this.props.single_id
    if (this.state.showMaxChartPopup) {
      id = 'max_' + id
    }
    if (!document.getElementById(id)) return
    let type = this.props.dataList.type
    // let single_id = this.props.single_id
    // let canvas = document.querySelector(`.comment_${single_id}`);
    // let canvas_width = window.getComputedStyle(canvas).width;
    // let canvas_width_val = parseInt(canvas_width.slice(0, canvas_width.length - 2))
    // this.setState({ canvas_width_val: canvas_width_val })
    if (type === 1 || type === 12 || type === 13 || type === 6) {
      this.toInitPieChart(id, isMaxSreen)
    } else if (type === 2) {
      this.toInitBarChart(id, isMaxSreen)
    } else if (type === 4 || type === 8 || type === 9) {
      this.toInitStackBarChart(id, isMaxSreen, type)
    } else if (type === 5 || type === 7) {
      this.toInitCustomBarChart(id, isMaxSreen)
    } else if (type === 15 || type === 14 || type === 22 || type === 23) {
      this.toInitMatrixLineChart(id, isMaxSreen)
    } else if (type === 19) {
      this.toInitBarChart2(id, isMaxSreen)
    } else if (type === 20) {
      this.generateHeatmap(isMaxSreen)
    }
  }

  // 生成generateHeatmap
  generateHeatmap(isMaxSreen) {
    setTimeout(() => {
      let heatmap_box = document.getElementById(`heatmap-image-container-${this.props.single_id}`)
      if (isMaxSreen) {
        heatmap_box = document.getElementById(`max-heatmap-image-container-${this.props.single_id}`)
      }
      if (!heatmap_box) return
      let heatmap_image_width = heatmap_box.offsetWidth
      let heatmap_image_height = heatmap_box.offsetHeight
      let percent = this.state.precision_list[this.state.precision_index].percent
      if (isMaxSreen) {
        percent = this.state.precision_list[this.state.precision_index_max].percent
      }

      // 区域 默认点
      // （1）如果不是 区域 ，纯点，那么就一个data 且 region 为空数组
      // （2）如果是 区域，那么就有多个data 且 region 为非空数组
      // 低精度 60px 中精度40px 高精度 20px
      let data = this.props.dataList.data
      let isRegion = false
      let points = []
      if (data.length === 1 && data[0].region.length === 0) {
        // 按照精度划分
        isRegion = false
        // let points = (data[0].points)
        points = this.getSinglePoints(data[0].points, isMaxSreen)

      } else {
        isRegion = true
        points = this.getRegionPoints(data)
        // 区域要把区域显示出来
      }
      let original_data = this.props.dataList.data
      let total_ppl_answered = original_data[0].value
      let heatmap_points = []
      let max = 0
      let total_val = 0
      var existingPoints = new Set()
      for (let j = 0; j < points.length; j++) {
        var val = points[j].val
        max = Math.max(max, val)
        var tmp = {
          x: Math.floor(points[j].x * heatmap_image_width),
          y: Math.floor(points[j].y * heatmap_image_height),
          value: val,
        }
        existingPoints.add(`${tmp['x']},${tmp['y']}`)
        heatmap_points.push(tmp)
        total_val += val
      }


      let heatmap_data = {
        max: max,
        data: heatmap_points,
      }

      var heatmapInstance_1 = Heatmap.create({
        container: heatmap_box,
        maxOpacity: 0.8,
        minOpacity: 0,
        // blur: 0.5,
        // radius: isRegion ? 50 : "",
        radius: isRegion ? 50 : percent * 10,
      })
      const oldCanvas = heatmap_box.children[2]
      // //删除之前生成的热力图
      if (oldCanvas !== undefined) {
        //删除之前的节点
        heatmap_box.removeChild(heatmap_box.children[1])
      }
      heatmap_box.children[1].id = 'heatmap-canvas-' + this.props.single_id
      if (isMaxSreen) {
        heatmap_box.children[1].id = 'max-heatmap-canvas-' + this.props.single_id
      }
      heatmapInstance_1.setData(heatmap_data)
      var tooltip = document.createElement('div')
      tooltip.style.position = 'absolute'
      tooltip.style.position = 'absolute'
      tooltip.style.display = 'none'
      tooltip.style.background = '#f9f9f9' // Light background color
      tooltip.style.border = '1px solid #d1d1d1' // Subtle border
      tooltip.style.borderRadius = '4px' // Rounded corners
      tooltip.style.boxShadow = '0 2px 4px rgba(0,0,0,0.18)' // Slight shadow for depth
      tooltip.style.padding = '8px 12px' // Padding for content
      tooltip.style.fontSize = '14px' // Appropriate font size
      tooltip.style.fontFamily = 'Arial, sans-serif' // Clean, readable font
      tooltip.style.color = '#333333' // Darker text for readability
      tooltip.style.zIndex = '1000' // High z-index to ensure visibility
      tooltip.style.cursor = 'default' // Default cursor
      tooltip.style.maxWidth = '200px' // Max width to avoid overly long tooltips


      document.body.appendChild(tooltip)

      var gridSizeX = Math.floor(heatmap_box.offsetWidth / (100 / percent))
      var gridSizeY = Math.floor(heatmap_box.offsetHeight / (100 / percent))
      // 用于统计，按照图片的10%
      var boxSizeX = Math.floor(heatmap_box.offsetWidth / 10) / 2
      var boxSizeY = Math.floor(heatmap_box.offsetHeight / 10) / 2

      // Function to sum up values of pixels within a block
      function sumBlockValues(gridX, gridY) {
        // TODO: METHOD 1: need a new method that counts the people click in the block 

        let original_points = original_data[0].points
        var counted_ppl = new Set()
        var sum = 0

        var start_x = gridX * gridSizeX - boxSizeX
        var start_y = gridY * gridSizeY - boxSizeY
        var end_x = gridX * gridSizeX + boxSizeX
        var end_y = gridY * gridSizeY + boxSizeY
        console.log(start_x, start_y, end_x, end_y)


        for (var i = 0; i < original_points.length; i++) {
          var x = Math.floor(original_points[i].x * heatmap_image_width / 100)
          var y = Math.floor(original_points[i].y * heatmap_image_height / 100)
          if (x >= start_x && x < end_x && y >= start_y && y < end_y) {
            let user_ids = original_points[i].user_ids
            for (var j = 0; j < user_ids.length; j++) {
              if (!counted_ppl.has(user_ids[j])) {
                counted_ppl.add(user_ids[j])
                sum += 1
              }
            }
          }
        }

        // console.log(sum);
        // console.log(counted_ppl);

        // METHOD 2: counting the points in the block 
        // var sum = 0;
        // var start_x = Math.max(0, gridX - 2) * gridSizeX;
        // var start_y = Math.max(0, gridY - 2) * gridSizeY;
        // var end_x = (gridX + 2) * gridSizeX;
        // var end_y = (gridY + 2) * gridSizeY;
        // // TODO: later maybe need to fix for other precisions......
        // for (var x = start_x; x < end_x; x++) {
        //   for (var y = start_y; y < end_y; y++) {
        //     if (existingPoints.has(`${x},${y}`)) {
        //       var value = heatmapInstance_1.getValueAt({ x: x, y: y });

        //       sum += value;
        //     }

        //   }
        // }
        return sum
      }

      // Function to update tooltip content and position
      function updateTooltip(gridX, gridY, rect, percent, ppl_count) {
        console.log(rect)
        var tooltipX = rect.x + (gridX * gridSizeX) + 30
        var tooltipY = rect.y + (gridY * gridSizeY)
        // var tooltipX = rect.left + (gridX * gridSizeX);
        // var tooltipY = rect.top + (gridY * gridSizeY);
        var content = `区块：(${gridX}, ${gridY}) <br>占比：${percent}<br>人数：${ppl_count}` // Fetch or calculate your data here

        tooltip.style.left = tooltipX + 'px'
        tooltip.style.top = tooltipY + 'px'
        tooltip.innerHTML = content
        tooltip.style.display = 'block'
      }

      var canvas = document.getElementById('heatmap-canvas-' + this.props.single_id)
      canvas.addEventListener('click', function (event) {
        var rect = canvas.getBoundingClientRect()

        console.log(event)
        var x = event.clientX - rect.left
        var y = event.clientY - rect.top

        var gridX = Math.floor(x / gridSizeX)
        var gridY = Math.floor(y / gridSizeY)

        // Retrieve data from heatmap (this part depends on how your data is structured)
        var data = sumBlockValues(gridX, gridY)

        // Update tooltip
        updateTooltip(gridX, gridY, rect, Math.round(data / total_ppl_answered * 100) + '%', data)
      })

      // Hide tooltip on mouseout
      canvas.addEventListener('mouseout', function () {
        tooltip.style.display = 'none'
      })

      this.setState({
        isRegion: isRegion,
        precision_list: isRegion ? this.state.precision_list_region : this.state.precision_list_single,
      }, () => {
        if (isRegion) {
          for (let i = 0; i < this.props.dataList.data.length; i++) {
            let region = this.props.dataList.data[i].region
            if (region.length) {
              this.drawCanvas(region, heatmap_image_width, heatmap_image_height, isMaxSreen)
            }
          }
        }

      })
    }, 1000)
  }

  // 画canvas
  drawCanvas(region, heatmap_image_width, heatmap_image_height, isMaxSreen) {
    let heatmap_canvas = document.getElementById(`heatmap-canvas-${this.props.single_id}`)
    if (isMaxSreen) {
      heatmap_canvas = document.getElementById(`heatmap-canvas-${this.props.single_id}`)
    }
    let ctx = heatmap_canvas.getContext('2d')
    ctx.beginPath()
    region.forEach((item) => {
      ctx.lineTo(item.x * heatmap_image_width / 100, item.y * heatmap_image_height / 100)
    })
    ctx.closePath()
    ctx.lineCap = 'round' //线条末端线帽
    ctx.lineJoin = 'round' //边角的类型
    ctx.strokeStyle = '#f55186'
    ctx.lineWidth = 8
    ctx.closePath()
    ctx.stroke()
  }

  // 区域
  getRegionPoints(data) {
    let cur_points = []
    for (let i = 0; i < data.length; i++) {
      let cur_val = 0
      let x_all = 0
      let y_all = 0
      let num = data[i].points.length
      for (let j = 0; j < data[i].points.length; j++) {
        cur_val += data[i].points[j].val
        x_all += data[i].points[j].x
        y_all += data[i].points[j].y
      }
      if (data[i].region.length) {
        x_all = 0
        y_all = 0
        for (let j = 0; j < data[i].region.length; j++) {
          x_all += data[i].region[j].x
          y_all += data[i].region[j].y
        }
        num = data[i].region.length
      }

      let point_obj = {
        x: x_all / num / 100,
        y: y_all / num / 100,
        val: cur_val,
      }
      cur_points.push(point_obj)
    }
    return cur_points
  }

  // 获取单个点
  getSinglePoints(points, isMaxSreen) {
    let cur_points = []
    let percent = this.state.precision_list[this.state.precision_index].percent

    if (isMaxSreen) {
      percent = this.state.precision_list[this.state.precision_index_max].percent
    }
    let interval = parseInt(100 / percent)
    let map = new Map()
    for (let i = 0; i < points.length; i++) {
      let x = points[i].x
      let y = points[i].y
      let x_interval = Math.round(x / percent)
      let y_interval = Math.round(y / percent)
      let key = x_interval + '_' + y_interval
      if (map.has(key)) {
        let cur_val = map.get(key)
        map.set(key, cur_val + points[i].val)
      } else {
        map.set(key, points[i].val)
      }
    }
    let tmp = 0
    // if (interval % 2 !== 0) {
    //   tmp = percent / 2 / 100
    // }
    for (let [key, value] of map) {
      let x_map = parseInt(key.split('_')[0])
      let y_map = parseInt(key.split('_')[1])
      let point_obj = {
        x: x_map / interval,
        y: y_map / interval,
        val: value,
      }
      cur_points.push(point_obj)
    }
    return cur_points
  }

  selectPrecisionChange(key, option, isMaxSreen) {
    let index = parseInt(option.key)
    if (isMaxSreen) {
      this.setState({
        precision_id_max: key,
        precision_index_max: index,
      }, () => {
        this.generateHeatmap(isMaxSreen)
      })
    } else {
      this.setState({
        precision_id: key,
        precision_index: index,
      }, () => {
        this.generateHeatmap(isMaxSreen)
      })
    }
  }

  // 切换图
  handleChangeChart(index) {
    if (this.state.chart_flag === index) {
      return
    }
    this.setState({
      chart_flag: index,
    })
  }

  //下载图表
  downloadChart() {
    let id = 'chart_' + this.props.single_id + '_wrapper'
    if (this.state.showMaxChartPopup) {
      id = 'max_chart_' + this.props.single_id + '_wrapper'
    }
    exportImg(
      document.getElementById(id),
      this.props.dataList.name,
    )
  }

  // 子组件传给父组件状态 是否放大图表
  canShowMaxChartPopup(object) {
    this.setState({
      showMaxChartPopup: object.showMaxChartPopup,
      max_chart_desired_user_list: null,
      max_chart_option_distribution: null,
    })
  }

  // 子组件传给父组件状态 是否点击柱子查看详情
  canShowDetailChartPopup(object) {
    this.setState({
      showDetailChartPopup: object.showDetailChartPopup,
    })
  }

  // 放大图表
  handleMaxChart() {
    this.setState({
      showMaxChartPopup: true,
    }, () => {
      if (this.props.isRightToList) {
        this.maxListRef.current.setMaxChart({
          dataList: this.props.dataList,
          single_id: this.props.single_id,
          isConsumer: this.props.isConsumer,
        })
      } else {
        this.maxChartRef.current.setMaxChart({
          dataList: this.props.dataList,
          single_id: this.props.single_id,
          isConsumer: this.props.isConsumer,
        })
      }
    })
  }

  // 切换词云
  handleWordCloud(index) {
    this.handleChangeChart(index)
    if (!this.state.dataList.hasOwnProperty('wordcloud_image')) {
      this.geWordCloudQuestion()
    }
  }

  async geWordCloudQuestion() {
    message.loading('词云数据较大，图片加载中...', 4)
    let question_id = this.props.dataList.question_id
    let obj = {
      question_id: question_id,
      survey_type: this.props.survey_type,
      product_id: '',
      survey_id: '',
      wish_id: '',
      age_list: this.props.selected_age_list,
      region_list: this.props.selected_region_list,
      gender_list: this.props.selected_gender_list,
      start_time: this.props.start_time,
      end_time: this.props.end_time,
    }
    if (this.props.survey_type === 1) {
      obj.wish_id = this.props.wish_id
    } else if (this.props.survey_type === 2) {
      obj.product_id = this.props.product_id
    } else if (this.props.survey_type === 4) {
      obj.survey_id = this.props.survey_id
    }
    try {
      const response = await geWordCloudQuestion(obj)
      if (!response) return
      let wordcloud_image = ''
      if (parseInt(response.status) === 204) {
      } else {
        let dataList = JSON.parse(JSON.stringify(this.props.dataList))
        wordcloud_image = response.data.image
        dataList['wordcloud_image'] = wordcloud_image
        this.setState({
          dataList: dataList,
        })
      }
      this.setState({
        wordcloud_image: wordcloud_image,
      })
      return response
    } catch (error) {

      throw error
    }
  }

  // 获取图表相关评论
  async getRelatedCommentList(bar_option_id, bar_option_name, isMaxChartClick) {
    this.setState({
      loading_detail_chart: 1,
    })
    let obj = {
      question_id: this.props.dataList.question_id,
      survey_type: this.props.survey_type,
      question_type: this.props.dataList.type,
      option_id: bar_option_id.join(','),
      product_id: '',
      survey_id: '',
      wish_id: '',
      value: '',
      age_list: this.props.selected_age_list,
      gender_list: this.props.selected_gender_list,
      region_list: this.props.selected_region_list,
      start_time: this.props.start_time,
      end_time: this.props.end_time,
    }
    if (this.props.survey_type === 1) {
      obj.wish_id = this.props.wish_id
    } else if (this.props.survey_type === 2) {
      obj.product_id = this.props.product_id
    } else if (this.props.survey_type === 4) {
      obj.survey_id = this.props.survey_id
    }

    // 先展示弹窗 再加载数据
    if (!isMaxChartClick) { //点击的尸首，这个图标是小屏点击
      if (this.props.isRightToList) {
        this.setState({
          showDetailChartPopup: true,
        })
      } else {
        this.setState({
          showDetailChartPopup: true,
        })
      }
    }

    try {
      const response = await getRelatedCommentList(obj)
      if (!response) return
      if (parseInt(response.status) === 204) {
        this.setState({
          loading_detail_chart: this.state.loading_detail_chart - 1,
        })
        message.warning('暂无数据', 2, () => {
        })
        if (isMaxChartClick) {
          this.setState({
            max_chart_desired_user_list: [],
            max_chart_option_distribution: [],
          })
        }
      } else {
        this.setState({
          loading_detail_chart: this.state.loading_detail_chart - 1,
        })
        let max_chart_option_distribution = []
        response.data.option_distribution.forEach((item, index) => {
          let chartObj = {name: item.name, type: item.type, data: [], sum: item.sum}
          for (let i = 0; i < item.data.length; i++) {
            let dataObj = {value: item.data[i].val, name: item.data[i].name || '未知'}
            chartObj.data.push(dataObj)
          }
          max_chart_option_distribution.push(chartObj)
        })
        if (isMaxChartClick) { // 大屏点击
          if (this.props.isRightToList) {
            this.setState({
              max_chart_desired_user_list: response.data.desired_user_list,
            })
          } else {
            this.setState({
              max_chart_option_distribution: max_chart_option_distribution,
            })
          }
        } else { // 小屏点击
          if (this.props.isRightToList) {
            this.handleDetailList(response.data.desired_user_list, bar_option_name)
          } else {
            this.handleDetailChart(max_chart_option_distribution, bar_option_name)
          }

        }
      }
      return response
    } catch (error) {
      this.setState({
          loading_detail_chart: 0,
        }, () => {
          if (error?.message !== 'isCancel') {
            message.error('后台发生错误')
          }
        },
      )
    }
  }

  //点击单个柱子看列表
  handleDetailList(desired_user_list, bar_option_name) {
    this.detailListRef.current.setDetailList({
      desired_user_list: desired_user_list,
      single_id: this.props.single_id,
      bar_option_name: bar_option_name,
      question_name: this.props.dataList.name,
    })
  }

  // 点击单个柱子看图表
  handleDetailChart(option_distribution, bar_option_name) {
    this.detailChartRef.current.setDetailChart({
      option_distribution: option_distribution,
      single_id: this.props.single_id,
      bar_option_name: bar_option_name,
      question_name: this.props.dataList.name,
    })
  }

  // 点击柱子
  onMouseDown() {
    let Xindex = JSON.parse(localStorage.getItem('Xindex'))
    if (!Xindex || (this.props.dataList.type !== 2 && this.props.dataList.type !== 1 && this.props.dataList.type !== 12 && this.props.dataList.type !== 13)) {
      return
    }

    let bar_index = ''
    let bar_option_id = ''
    let bar_option_name = ''
    if (this.props.dataList.type === 2) {
      bar_index = Xindex[0].dataIndex
      bar_option_id = this.props.dataList.option_id_list[bar_index]
      bar_option_name = this.props.dataList.xAxisList[bar_index]
    } else if (this.props.dataList.type === 1 || this.props.dataList.type === 12 || this.props.dataList.type === 13) {
      bar_index = Xindex.dataIndex
      bar_option_id = this.props.dataList.data[bar_index].option_id
      bar_option_name = this.props.dataList.data[bar_index].name
    }

    localStorage.removeItem('Xindex')
    this.getRelatedCommentList(bar_option_id, bar_option_name)
  }

  // 鼠标移入
  onMouseOver = () => {
    let name_div = document.querySelector(`.title-left-${this.props.single_id}`)
    if (name_div.clientHeight >= name_div.scrollHeight) { // 没有溢出
      if (this.props.dataList.question_img) {
        let name_div_width = name_div.offsetWidth - 2
        let question_hover_div = document.querySelector(`.question-hover-card-${this.props.single_id}`)
        question_hover_div.style.width = name_div_width + 'px'
        this.setState({
          isMouseOn: true,
          showQuestionName: false,
        }, () => {
          let tmp_height = question_hover_div.offsetHeight + 8
          question_hover_div.style.top = '-' + tmp_height + 'px'
        })
      }
      return
    }
    this.setState({
      isMouseOn: true,
      showQuestionName: true,
    }, () => {
      let name_div_width = name_div.offsetWidth - 2
      let question_hover_div = document.querySelector(`.question-hover-card-${this.props.single_id}`)
      let tmp_height = question_hover_div.offsetHeight + 8
      question_hover_div.style.top = '-' + tmp_height + 'px'
      question_hover_div.style.width = name_div_width + 'px'

    })
  }
  // 鼠标移出
  onMouseOut = (index) => {
    this.setState({showQuestionName: false, isMouseOn: false})
  }

  // 放大图片
  enlargeQuestionImg(value) {
    this.setState({
      enlarge_question_img: value,
    })
  }

  render() {
    const prefix = (
      <img src={cur_icon_search} alt="" className="evaluation-icon-search"/>
    )
    let single_id = String(this.props.single_id) || ''
    let name = this.props.dataList.name || ''
    let question_img = this.props.dataList.question_img || ''
    let type = this.props.dataList.type
    // let name = "吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧吧"
    return (
      <div className={`preinstall-card`}>
        {
          this.state.loading_detail_chart <= 0 ? '' : <Loading loadingText={this.state.loadingText}></Loading>
        }
        <div className="card-title-wrapper">
          {/* <Tooltip placement="top" title={name}>
            <span className='title-left'>{name}</span>
          </Tooltip> */}
          <div className="title-left-wrapper" onMouseOver={() => this.onMouseOver()}
               onMouseOut={() => this.onMouseOut()}>
            <div className={`title-left title-left-${this.props.single_id}`}>{name}</div>
            <div
              className={`question-hover-card question-hover-card-${this.props.single_id} ${this.state.isMouseOn ? '' : 'isHide'} `}>
              <div className="question-hover-card-arrow"></div>
              {
                this.state.showQuestionName ? <div className="question-hover-card-name">{name}</div> : ''
              }
              {
                question_img ? <div className="question-hover-card-img"><img src={question_img} alt=""/></div> : ''
              }
            </div>
          </div>
          {
            question_img ?
              <img src={require('../../images/overall_data/pic_enlarge.png').default} alt="" className="title-right-pic"
                   onClick={this.enlargeQuestionImg.bind(this, true)}/> : ''
          }
          <Image
            style={{display: 'none'}}
            preview={{
              visible: this.state.enlarge_question_img,
              src: question_img,
              onVisibleChange: (value) => {
                this.enlargeQuestionImg(value)
              },
            }}
          />
        </div>

        <div className="chart-operaion-wrapper">
          <div className="chart-change-wrapper">
            <div className="chart-change-text" onClick={this.handleMaxChart.bind(this)}>放大</div>
            <div className="chart-change-sign">|</div>
            <div className="chart-change-text" onClick={this.downloadChart.bind(this)}>下载</div>
          </div>
          {
            type === 1 || type === 2 || type === 5 || type === 6 || type === 7 || type === 8 || type === 12 || type === 13 || type === 15 || type === 14 || type === 19 || type === 20 || type === 22 || type === 23 ?
              <div className="chart-change-wrapper">
                <div className={`chart-change-text ${this.state.chart_flag === 0 ? 'chart-change-text-selected' : ''}`}
                     onClick={this.handleChangeChart.bind(this, 0)}>图表
                </div>
                <div className="chart-change-sign">|</div>
                <div className={`chart-change-text ${this.state.chart_flag === 1 ? 'chart-change-text-selected' : ''}`}
                     onClick={this.handleChangeChart.bind(this, 1)}>列表
                </div>
              </div> : ''
          }
          {
            type === 3 || type === 16 || type === 18 ? <div className="chart-change-wrapper">
              <div className={`chart-change-text ${this.state.chart_flag === 0 ? 'chart-change-text-selected' : ''}`}
                   onClick={this.handleChangeChart.bind(this, 0)}>列表
              </div>
              <div className="chart-change-sign">|</div>
              <div className={`chart-change-text ${this.state.chart_flag === 1 ? 'chart-change-text-selected' : ''}`}
                   onClick={this.handleWordCloud.bind(this, 1)}>词云
              </div>
            </div> : ''
          }
        </div>
        {/* 显示真正的图表 */}
        <div className="chart-wrapper" id={`chart_${this.props.single_id}_wrapper`}>
          <div
            className={this.state.chart_flag === 1 || type === 3 || type === 16 || type === 18 || type === 10 || type === 20 ? 'isHide' : 'chart-wrapper-2'}>
            <div id={`chart_${this.props.single_id}`}
                 className={`echart-container preinstall-echart comment_${single_id}`}
                 onMouseDown={this.onMouseDown.bind(this)}></div>
          </div>
          <div className={(type === 20 && this.state.chart_flag === 0) ? 'chart-wrapper-2 abcd' : 'isHide'}>
            {/* <div id={`chart_${this.props.single_id}`} className={`echart-container preinstall-echart comment_${single_id}`}>
              <img src={question_img} alt='' />
            </div> */}
            <div className="heatmap-select">
              <XmSelect
                dropdownClassName="download-type-dropdown"
                placeholder="请选择"
                value={this.state.precision_id}
                options={this.state.precision_list}
                onChange={(e, index) => {
                  this.selectPrecisionChange(e, index)
                }}>
              </XmSelect>
            </div>
            <div className={`echart-container comment_${single_id} heatmap-image-container`}
                 id={`heatmap-image-container-${this.props.single_id}`}>
              <img src={question_img} alt=""/>
            </div>
          </div>
          <div className={this.state.chart_flag === 0 ? 'isHide' : ''}>
            {
              type === 3 || type === 16 || type === 18 ?
                <div className="wordcloud-image-wrapper">
                  {this.state.wordcloud_image ?
                    <img src={this.state.wordcloud_image} alt=""/> : <div className="wordcloud-image-empty">
                      <ConfigProvider locale={zhCN}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                      </ConfigProvider>
                    </div>
                  }
                </div> : ''
            }
            {
              type === 1 || type === 2 || type === 5 || type === 6 || type === 7 || type === 8 || type === 12 || type === 13 || type === 20 ?
                <ChartList
                  isConsumer={this.props.isConsumer}
                  dataList={this.props.dataList}
                  questionType={type}
                  isMaxChart={false}
                ></ChartList> : ''
            }

            {
              type === 15 || type === 14 ? <MatrixNpsList
                isConsumer={this.props.isConsumer}
                dataList={this.props.dataList}
                isMaxChart={false}
              ></MatrixNpsList> : ''
            }
            {
              type === 19 ? <MaxdiffList
                dataList={this.props.dataList}
                isMaxChart={false}
              ></MaxdiffList> : ''
            }
            {
              type === 22 || type === 23 ?
                <TableList
                  dataList={this.props.dataList}
                  isMaxChart={false}>
                </TableList> : ''
            }
          </div>
          <div className={this.state.chart_flag === 1 ? 'isHide' : ''}>
            {
              type === 3 || type === 18 || type === 10 ?
                <BlankList
                  dataList={this.props.dataList}
                  isMaxChart={false}>
                </BlankList> : ''
            }
            {
              type === 16 ?
                <OpenBlankList
                  dataList={this.props.dataList}
                  isMaxChart={false}>
                </OpenBlankList> : ''
            }

          </div>
          {/* 大屏幕 右边展示列表 */}
          {
            this.state.showMaxChartPopup && this.props.isRightToList ? <div className="chart-wrapper-2">
              <MaxListPopup ref={this.maxListRef}
                            canShowMaxChartPopup={this.canShowMaxChartPopup.bind(this)}
                            downloadChart={this.downloadChart.bind(this)}
                            toInitChart={this.toInitChart.bind(this)}
                            wordcloud_image={this.state.wordcloud_image}
                            geWordCloudQuestion={this.geWordCloudQuestion.bind(this)}
                            getRelatedCommentList={this.getRelatedCommentList.bind(this)}
                            desired_user_list={this.state.max_chart_desired_user_list}
              ></MaxListPopup>
            </div> : ''
          }
          {/* 大屏幕 右边展示图表 */}
          {
            this.state.showMaxChartPopup && !this.props.isRightToList ? <div className="chart-wrapper-2">
              <MaxChartPopup ref={this.maxChartRef}
                             canShowMaxChartPopup={this.canShowMaxChartPopup.bind(this)}
                             downloadChart={this.downloadChart.bind(this)}
                             toInitChart={this.toInitChart.bind(this)}
                             wordcloud_image={this.state.wordcloud_image}
                             geWordCloudQuestion={this.geWordCloudQuestion.bind(this)}
                             getRelatedCommentList={this.getRelatedCommentList.bind(this)}
                             desired_user_list={this.state.max_chart_desired_user_list}
                             option_distribution={this.state.max_chart_option_distribution}
                             selectPrecisionChange={this.selectPrecisionChange.bind(this)}
                             precision_list={this.state.precision_list}
                             precision_id={this.state.precision_id_max}
              ></MaxChartPopup>
            </div> : ''
          }
          {/* 小屏幕 右边展示列表 */}
          {
            this.state.showDetailChartPopup && this.props.isRightToList ? <div className="chart-wrapper-2">
              <DetailListPopup ref={this.detailListRef}
                               canShowDetailChartPopup={this.canShowDetailChartPopup.bind(this)}
              ></DetailListPopup>
            </div> : ''
          }
          {/* 小屏幕 右边展示图表 */}
          {
            this.state.showDetailChartPopup && !this.props.isRightToList ? <div className="chart-wrapper-2">
              <DetailChartPopup ref={this.detailChartRef}
                                canShowDetailChartPopup={this.canShowDetailChartPopup.bind(this)}
              ></DetailChartPopup>
            </div> : ''
          }
        </div>
      </div>
    )
  }
}
