import React, { Component } from "react";
import "./question_edit_popup.scss";
import cur_icon_close_gre from "../../../images/cur_icon_close_gre.png";
import XmEditor from "../../Xm_editor/xm_editor";

export default class question_edit_popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question_name: "",
      question_name_prev: "",
      show: "none",
    };
  }
  componentDidMount() {
    this.setState({
      question_name: this.props.question_name,
      question_name_prev: this.props.question_name,
    });
  }
  // 关弹窗
  tags_close() {
    this.props.handleQuestionEditPopupStatus({
      name: this.state.question_name_prev,
    });
    this.setState({ show: "none" });
  }
  // 确认弹窗
  tags_confirm() {
    this.props.handleQuestionEditPopupStatus({
      name: this.state.question_name,
    });
    this.setState({ show: "none" });
  }
  handleEditorChange(val) {
    this.setState({
      question_name: val,
    });
  }
  // 打开弹窗
  open() {
    console.log("打开弹窗");
    this.setState({ show: "flex" });
  }
  render() {
    return (
      <div className="edit-question-popup" style={{ display: this.state.show }}>
        <div className="edit-question-popup-contain">
          <div className="edit-question-popup-top">
            <div className="popup-top-title">富文本编辑</div>
            <div className="popup-delete-icon">
              <img
                src={cur_icon_close_gre}
                className="cursor"
                onClick={this.tags_close.bind(this)}
                alt=""
              />
            </div>
          </div>
          <div className="edit-question-popup-content">
            <div className="edit-question-tip">请编辑选项</div>
            <div className="question-name-editor">
              <XmEditor
                html={this.state.question_name}
                handleEditorChange={this.handleEditorChange.bind(this)}
              ></XmEditor>
            </div>
          </div>
          <div className="tag-btn">
            <div onClick={this.tags_confirm.bind(this)}>确认</div>
            <div onClick={this.tags_close.bind(this)}>取消</div>
          </div>
        </div>
      </div>
    );
  }
}
