import "./blank_template_info.scss";
import React, { Component } from "react";

export default class blank_template_info extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="blank-template-info">
        <div className="blank-template-input"></div>
      </div>
    );
  }
}
