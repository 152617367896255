export const question_authority_obj = {
  question_num: {
    1: 30, // 基础
    2: 30, // 进阶
    3: 30, // 深度
    4: 100, // 定制
  },
  rule_1: {
    // 种草提固定为第一道题
    1: true,
    2: true,
    3: true,
    4: true,
  },
  rule_2: {
    // 用户不可以改变产品问卷前三题的位置
    1: true,
    2: false,
    3: false,
    4: false,
  },
  rule_3: {
    // 用户可配置的题目的类型有限
    1: {
      need_obey: true,
      question_type_list: [1, 2, 4, 22, 23],
    },
    2: {
      need_obey: false,
      question_type_list: [],
    },
    3: {
      need_obey: false,
      question_type_list: [],
    },
    4: {
      need_obey: false,
      question_type_list: [],
    },
  },
  rule_4: {
    // 用户可配置的简答题、枚举题、开放题数量
    1: {
      need_obey: true,
      relative_question_sum: 0,
    },
    2: {
      need_obey: true,
      relative_question_sum: 1,
    },
    3: {
      need_obey: true,
      relative_question_sum: 3,
    },
    4: {
      need_obey: true,
      relative_question_sum: 10,
    },
  },
  rule_5: {
    // 用户不可以使用任何的跳题功能
    1: true,
    2: false,
    3: false,
    4: false,
  },
};
